
.bibleText {
  font-family: 'Libre Baskerville', serif;
  max-width: 500px;
  hyphens: auto;
}

.lectioHeader {
  font-family: 'Cinzel Decorative', 'Libre Baskerville', serif !important;
  font-weight: 300;
}

.refHeader {
  font-family: 'Libre Baskerville', serif !important;
  font-weight: 300;
}
